import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { TextIconButton } from "../../../../components/Buttons";
import { SelectDropdown, SelectDropdownRef } from "../../../../components/Dropdown";
import { useFaq } from "../../../../contexts/faq";
import { FaqArticle, FaqTopic } from "../../../../types/FaqArticle";
import { AddArticleModal, FaqItem } from "./components";
import { Container, DroppableArea, FakeBtn, FaqHeader, RightHeaderInfos } from "./styles";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ReactComponent as DragEnabledIcon } from "../../../../assets/images/dragEnabledIcon.svg";
import { ReactComponent as DragDisabledIcon } from "../../../../assets/images/dragDisabledIcon.svg";
import { Loader, StatusModal } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useDevice } from "../../../../contexts/device";
import { toast } from "react-hot-toast";
import { useWindowDimensions } from "../../../../hooks";
import { SCREEN_SIZE } from "../../../../constants";


const Faq = () => {
	const { deviceListWithoutDefault } = useDevice();
	const { selectedApp } = useMobileApp();
	const { width } = useWindowDimensions();

	const { getFaqList, saveNewOrder } = useFaq();

	const [faqs, setFaqs] = useState<FaqArticle[] | null>(null);
	const [articleModalVisible, setArticleModalVisible] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });
	const [isDragDisabled, setIsDragDisabled] = useState<boolean>(true);

	const faqsRef = useRef<FaqArticle[] | null>(null);
	const selectDropdownRef = useRef<SelectDropdownRef>(null);
	
	const deviceNames = deviceListWithoutDefault.map(value => value.name);
	const deviceImages = deviceListWithoutDefault.map(value => value.image ?? "");
	
	useEffect(() => {
		setFaqs(null);
		refreshList();
	}, [selectedApp]);

	function handleOnDragEnd(result: DropResult) {
		if (!result.destination || faqs === null) return;

		const items = Array.from(faqs);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		items.forEach((item, index) => {
			item.index = index;
		});

		setFaqs(items);
		const t = toast.loading(MESSAGES.PT_BR.FAQ_MESSAGES.LOADING);
		saveNewOrder(selectedApp.appCode, items)
			.then(() => {
				toast.success(MESSAGES.PT_BR.FAQ_MESSAGES.SUCCESS);
			}).catch(() => {
				toast.error(MESSAGES.PT_BR.FAQ_MESSAGES.ERROR);
			}).finally(() => {
				toast.dismiss(t);
			});
	}

	const handleShowFeedback = (statusFeedback) => {
		if (statusFeedback !== null) {
			selectDropdownRef.current?.clearSelection();
			refreshList();
			setStatusModal(statusFeedback);
			setTimeout(() => {
				setStatusModal({ isOpen: false, title: "", description: "", success: false });
			}, 3000);
		}
	};

	const refreshList = () => {
		getFaqList(selectedApp.appCode).then((response) => {
			const sortedFaqs = response?.sort((a, b) => a.index - b.index) ?? [];
			setFaqs(sortedFaqs);
			faqsRef.current = sortedFaqs;
		});
	};

	const filterSelectedDevices = (deviceNames: string[]) => {
		if (!faqsRef.current) return;

		if (deviceNames.length === 0) {
			setFaqs(faqsRef.current);
			return;
		}

		const selectedSlugs = deviceNames.map(deviceName => {
			return deviceListWithoutDefault.find(device => device.name === deviceName)?.slug;
		});

		const filteredFaq: FaqArticle[] = [];
		for (let i = 0; i < faqsRef.current.length; i++) {
			const faq = faqsRef.current[i];
			const faqToAdd = { ...faq, topics: [] as FaqTopic[]};

			for (let j = 0; j < faq.topics.length; j++) {
				const topic = faq.topics[j];

				for (let k = 0; k < topic.devices.length; k++) {
					const topicDeviceSlug = topic.devices[k];
					if (selectedSlugs.includes(topicDeviceSlug)) {
						faqToAdd.topics.push(topic);
						break;
					}
				}
			}
			if(faqToAdd.topics.length)
				filteredFaq.push(faqToAdd);
		}
		setFaqs(filteredFaq);
	};

	return (
		<Container>
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			<AddArticleModal
				isOpen={articleModalVisible}
				onClose={(statusFeedback) => {
					setArticleModalVisible(false);
					handleShowFeedback(statusFeedback);
				}}
			/>
			<FaqHeader>
				<h1>{MESSAGES.PT_BR.FAQ_MESSAGES.THEME_TITLE}</h1>
				<RightHeaderInfos>
					{
						width > SCREEN_SIZE.DESKTOP &&
						<>
							<FakeBtn onClick={() => setIsDragDisabled(!isDragDisabled)}>
								<div>
									{isDragDisabled ? <DragDisabledIcon /> : <DragEnabledIcon />}
								</div>
								<p>{isDragDisabled ? MESSAGES.PT_BR.FAQ_MESSAGES.DRAG_DISABLED : MESSAGES.PT_BR.FAQ_MESSAGES.DRAG_ENABLED}</p>
							</FakeBtn>
							<SelectDropdown 
								forwardedRef={selectDropdownRef}
								style={{ marginRight: "30px" }}
								items={deviceNames}
								itemImages={deviceImages}
								onSave={filterSelectedDevices}
								placeholder={"Filtrar relógio"}
							/>
						</>	
					}
					<TextIconButton
						text={MESSAGES.PT_BR.FAQ_MESSAGES.NEW_ARTICLE_BTN}
						onClick={() => { setArticleModalVisible(true); }}
						svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
						enabled
					/>
				</RightHeaderInfos>
			</FaqHeader>
			{faqs === null ?
				<Loader /> :
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="characters">
						{(provided) => (
							<DroppableArea {...provided.droppableProps} ref={provided.innerRef}>
								{faqs?.map((item, index) => {
									return (
										<Draggable key={item.theme} draggableId={item.theme} index={index} isDragDisabled={isDragDisabled} >
											{(provided) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<FaqItem
														faq={item}
														onFeedbackRequested={handleShowFeedback}
													/>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</DroppableArea>
						)}
					</Droppable>
				</DragDropContext>
			}
		</Container>
	);
};

export default Faq;